<template>
  <item-wrapper id="contact">
    <item-header :title="t('contactUs.title')"/>
    <main>
      <div class="left-contact">
        <div class="content-title">
          {{ t('contactUs.contact1') }}
        </div>
        <p>
          {{ t('contactUs.contact2') }}
        </p>
        <div class="content-note">
          <div class="content-note-title">
            <span>{{ t('contactUs.dispatchNote') + ':'}}</span>
          </div>
          <p>
            {{ t('contactUs.contact3') }}
          </p>
        </div>
      </div>

      <div class="right-contact">
        <div class="content-title">{{ t('contactUs.sendUsAMessage') }}</div>
        <a-divider type="horizontal" />
        <a-form layout="vertical" :model="sendMessage" @submit="onSubmit">
          <a-form-item
            class="name"
            v-model:value="sendMessage.Name"
            v-bind="sendMessageValidateInfos.Name"
          >
            <a-input
              v-model:value="sendMessage.Name"
              aria-label="Name"
              allow-clear
            >
              <template #prefix>{{ t('contactUs.name')}}</template>
            </a-input>
          </a-form-item>
          <a-form-item
            class="email"
            v-model:value="sendMessage.Email"
            v-bind="sendMessageValidateInfos.Email"
          >
            <a-input
              v-model:value="sendMessage.Email"
              aria-label="Email"
              allow-clear
            >
              <template #prefix>{{ t('user.email')}}</template>
            </a-input>
          </a-form-item>
          <a-form-item
            class="message"
            v-bind="sendMessageValidateInfos.Message"
          >
            <a-textarea
              v-model:value="sendMessage.Message"
              :placeholder="t('contactUs.placeholder')"
              :rows="2"
            />
          </a-form-item>
          <a-button type="primary" html-type="submit" aria-label="Submit"
            >{{ t('contactUs.send') }}</a-button
          >
        </a-form>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent, reactive } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import {
  Form, Input, Button, Divider,
} from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import { Alert } from '@/components/Alert/index';
import { contactMessage } from '@/services/user';
import { useI18n } from 'vue-i18n';

type FormRules = Record<string, ValidationRule | ValidationRule[]>;
type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'Contact',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
      Form,
      Form.Item,
      Input,
      Button,
      Divider,
      Input.TextArea,
    ),
  },
  setup() {
    const { t } = useI18n();

    const sendMessage = reactive({
      Name: '',
      Email: '',
      Message: '',
    });

    const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
    // 邮箱验证
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validateEmail: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('contactUs.isRequired'));
      }
      if (regex.test(value)) {
        return Promise.resolve();
      }
      return rejectFunc(t('contactUs.emailMessage'));
    };
    const sendMessageRules = reactive({
      Name: [{ required: true, message: t('contactUs.isRequired') }],
      Email: [{ validator: validateEmail, required: true, trigger: 'change' }],
      Message: [{ required: true, message: t('contactUs.isRequired') }],
    });
    const {
      validate: sendMessageValidate,
      validateInfos: sendMessageValidateInfos,
      resetFields,
    } = useForm(sendMessage, sendMessageRules);

    const onSubmit = async () => {
      try {
        const fields = await sendMessageValidate();
        const dataForContact = {
          email: fields.Email,
          message: fields.Message,
          name: fields.Name,
        };
        const res = await contactMessage(dataForContact);
        if (res.success) {
          const modal = Alert.success(t('contactUs.sendSuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          resetFields();
        }
      } catch (e) {
        console.error(e);
      }
    };
    return {
      sendMessage,
      onSubmit,
      sendMessageValidate,
      sendMessageValidateInfos,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include container();
  width: 61%;
  padding: 0;
  display: flex;
  margin: 0 auto 70px;
  justify-content: space-around;

  .content-title {
    font-size: 20px;
    font-weight: 800;
    color: #1c1c1c;
    line-height: 24px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #545454;
    line-height: 27px;
    margin-right: 35px;
  }
  .left-contact {
    width: 50%;

    .content-note {
      padding-left: 20px;
      border: 2px solid #d7d7d7;
      .content-note-title {
        font-size: 20px;
        font-weight: 800;
        color: #fbc400;
        line-height: 24px;
        margin: 20px 0;
      }
    }
  }
  .right-contact {
    .ant-divider-horizontal {
      margin: 20px 0 29px;
      width: 100%;
      min-width: 75%;
    }
    :deep(.ant-form) {
      .ant-form-item {
        position: relative;
        width: 100%;
        margin-bottom: 29px;
        display: flex;

        .ant-form-item-control-wrapper {
          .ant-form-item-control {
            position: relative;
            .ant-form-item-children {
              .ant-input-affix-wrapper {
                .ant-input-prefix {
                  font-size: 14px;
                  font-weight: 400;
                  color: $font-color-weak;
                  line-height: 17px;
                  &::after {
                    display: inline-block;
                    margin-left: 4px;
                    color: $theme-color;
                    content: "*";
                    font-size: 16px;
                    font-weight: 500;
                  }
                }
                .ant-input {
                  height: 30px;
                  padding: 0;
                  line-height: 40px;
                  font-size: 14px;
                  padding: 0 6px;
                }
                &:hover {
                  border-color: $theme-color;
                }
              }
              .ant-input-affix-wrapper-focused {
                border: 1px solid $theme-color;
                box-shadow: $box-shadow;
              }
            }
            .ant-form-explain {
              position: absolute;
              left: 0;
              font-size: 12px;
              font-weight: 400;
              color: $theme-color;
              line-height: 15px;
            }
          }
        }
        &.message {
          .ant-input {
            border-radius: 4px;
            box-sizing: border-box;
            height: 94px;
            padding: 0 10px;
            outline: none;
            line-height: 20px;
            transition: all 250ms;
            background: transparent;
            font-size: 14px;
          }
        }
      }
    }
    textarea {
      &::-webkit-input-placeholder {
        color: $font-color-weak;
      }
      &::-moz-input-placeholder {
        color: $font-color-weak;
      }
      &::-ms-input-placeholder {
        color: $font-color-weak;
      }
      &:hover {
        border-color: $theme-color;
      }
      &:focus {
        border-color: $theme-color;
        box-shadow: 0 0 0 2px rgb(230 0 44 / 20%);
      }
    }
    .ant-btn-primary {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      width: 100%;
      height: 38px;
      border-radius: 4px;
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
}
</style>
