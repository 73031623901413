
import importComponents from '@/utils/import-components';
import { defineComponent, reactive } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import {
  Form, Input, Button, Divider,
} from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import { Alert } from '@/components/Alert/index';
import { contactMessage } from '@/services/user';
import { useI18n } from 'vue-i18n';

type FormRules = Record<string, ValidationRule | ValidationRule[]>;
type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'Contact',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
      Form,
      Form.Item,
      Input,
      Button,
      Divider,
      Input.TextArea,
    ),
  },
  setup() {
    const { t } = useI18n();

    const sendMessage = reactive({
      Name: '',
      Email: '',
      Message: '',
    });

    const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
    // 邮箱验证
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validateEmail: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('contactUs.isRequired'));
      }
      if (regex.test(value)) {
        return Promise.resolve();
      }
      return rejectFunc(t('contactUs.emailMessage'));
    };
    const sendMessageRules = reactive({
      Name: [{ required: true, message: t('contactUs.isRequired') }],
      Email: [{ validator: validateEmail, required: true, trigger: 'change' }],
      Message: [{ required: true, message: t('contactUs.isRequired') }],
    });
    const {
      validate: sendMessageValidate,
      validateInfos: sendMessageValidateInfos,
      resetFields,
    } = useForm(sendMessage, sendMessageRules);

    const onSubmit = async () => {
      try {
        const fields = await sendMessageValidate();
        const dataForContact = {
          email: fields.Email,
          message: fields.Message,
          name: fields.Name,
        };
        const res = await contactMessage(dataForContact);
        if (res.success) {
          const modal = Alert.success(t('contactUs.sendSuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          resetFields();
        }
      } catch (e) {
        console.error(e);
      }
    };
    return {
      sendMessage,
      onSubmit,
      sendMessageValidate,
      sendMessageValidateInfos,
      t,
    };
  },
});
